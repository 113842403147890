//@import url('https://fonts.googleapis.com/css?family=Roboto+Slab');

$heading-font: "Roboto Slab", $main-font;

$heading-font-path : "../webfonts/roboto-slab";

//
// The following snippets were generated through: https://gwfh.mranftl.com/fonts/roboto-slab?subsets=latin,latin-ext
//
/* roboto-slab-regular - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 400;
  src: url('#{$heading-font-path}/roboto-slab-v24-latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
       url('#{$heading-font-path}/roboto-slab-v24-latin_latin-ext-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-slab-700 - latin_latin-ext */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto Slab';
  font-style: normal;
  font-weight: 700;
  src: url('#{$heading-font-path}/roboto-slab-v24-latin_latin-ext-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
       url('#{$heading-font-path}/roboto-slab-v24-latin_latin-ext-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
