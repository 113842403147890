//@import url('https://fonts.googleapis.com/css?family=Poppins');

$main-font: "Poppins", sans-serif;
$main-font-size: 17px;
$main-font-size-small-screen: 15px; // tested on an actual iPhone

$main-font-path : "../webfonts/poppins";

//
// The following snippets were generated through: https://gwfh.mranftl.com/fonts/poppins?subsets=latin,latin-ext
//
/* poppins-regular - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('#{$main-font-path}/poppins-v20-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$main-font-path}/poppins-v20-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-italic - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('#{$main-font-path}/poppins-v20-latin-ext_latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$main-font-path}/poppins-v20-latin-ext_latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-700 - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('#{$main-font-path}/poppins-v20-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$main-font-path}/poppins-v20-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* poppins-700italic - latin-ext_latin */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('#{$main-font-path}/poppins-v20-latin-ext_latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('#{$main-font-path}/poppins-v20-latin-ext_latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
